import React, { useEffect, useState } from 'react';
import './section-2.css';
import ClupLogo from '../images/clup_logo.png';
import GithubLogo from '../images/github.png';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import TextModel from '../interfaces/TextModel';
import { API } from 'aws-amplify';


type Props = {
  delay?: number,
  // Single element or multiple
  children?: JSX.Element | JSX.Element[]
}

function FadeIn( { delay, children }: Props ) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [ inView ]);

  return (
    <motion.div
      className='project_card'
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{
        duration: 1.35,
        ease: 'easeInOut',
        delay: delay || .1
      }}
      variants={{
        visible: { 
          opacity: 1
        },
        hidden: { 
          opacity: 0
        }
      }}
    >
      { children }
    </motion.div>
  );
}


function Section2() {
  const [clup, setClup] = useState<TextModel | undefined>(undefined);
  const [wallscape, setWallscape] = useState<TextModel | undefined>(undefined);


  useEffect(() => {
    (async () => {
      try {
        let response = await API.get('portfolioAPI', '/getText/clup');
        setClup(response);
        response = await API.get('portfolioAPI', '/getText/wallscape');
        setWallscape(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);


  return (
    <>
      <section className='section2'>
        <div className="container sec2">
          <h1 className='work_h1'>
              Work
          </h1>

          <FadeIn>
            <div className="project_title">
              <h2 className="project_name">Customer Lineup (Student Project)</h2>
              <h2 className="project_year">2020 - 2021</h2>
            </div>

            <div className="project_description">
              <div className="icons">
                <span className='font-icons icon-react'></span><strong>React</strong>
                <span className='font-icons icon-database'></span><strong>MongoDB</strong>
                <span className='font-icons icon-javascript'></span><strong>Express</strong>
              </div>
              <div className="hosting">
                <strong> Hosting:</strong> Heroku
              </div>
              
              <p style={{marginTop: '1em'}}>
                <strong>Problem:</strong>   <br />
                { clup ? clup.data[0]: ''}
              </p>

              <p style={{marginTop: '1em'}}>
                <strong>Description:</strong>   <br />
                { clup ? clup.data[1]: ''}         
              </p>

              <p style={{marginTop: '1em'}}>
                <strong>Features:</strong>
              </p>
              <ul className='features_ul'>
                <li>JSON web tokens for authentication</li>
                <li>Role based access control for managers, employees, and customers</li>
                <li>Customer email alerts and email verification sent from Nodemailer</li>
                <li>Scheduled node-cron task to keep track of late appointments</li>
                <li>Fully custom appointment scheduler</li>
              </ul>          

                
              <p style={{marginTop: '1em'}}>
                <strong>What I Would Change:</strong>   <br />
                { clup ? clup.data[2]: ''}         
              </p>
            </div>
            <div className="bottom-btns">
              <div className="left">
                <a href="https://github.com/OnlyInSpace/CLUP">
                  <img src={GithubLogo} />
                </a>
                <a href="https://github.com/OnlyInSpace/CLUP">
                  <img src={ClupLogo} />
                </a>
              </div>       

              <div className="right">
                <a href="https://customerlineup.herokuapp.com/">
                  <h3>Demo</h3>
                </a>
              </div>
            </div>
          </FadeIn>

          <FadeIn>
            <div className="project_title">
              <h2 className="project_name">Wallscape Studios (Client Work)</h2>
              <h2 className="project_year">2021</h2>
            </div>


            <div className="project_description">
              <div className="icons">
                <span className='font-icons icon-react'></span><strong>React</strong>
                <span className='font-icons icon-database'></span><strong>MongoDB</strong>
                <span className='font-icons icon-javascript'></span><strong>Express</strong>
              </div>
              <div className="hosting">
                <strong> Hosting:</strong> Heroku 
              </div>

              <p style={{marginTop: '1em'}}>
                <strong>Description:</strong>   <br />
                { wallscape ? wallscape.data[0]: ''}         
              </p>

              <p style={{marginTop: '1em'}}>
                <strong>Features:</strong>
              </p>
              <ul className='features_ul'>
                <li>Contact form utilizing Nodemailer for sending emails</li>
                <li>Fully responsive UI</li>
              </ul>
            </div>

            <div className="bottom-btns">
              <div className="left client">
                {/* <img src={ClupLogo} /> */}
              </div>       

              <div className="right">
                <a href="https://www.wallscapestudios.com/">
                  <h3>Site</h3>
                </a>
              </div>
            </div>
          </FadeIn>

          <FadeIn>
            <div className="project_title">
              <h2 className="project_name">Atoll (Student Project)</h2>
              <h2 className="project_year">2021</h2>
            </div>


            <div className="project_description">
              <div className="icons">
                <span className='font-icons icon-unreal-engine'></span>
                <strong>Unreal Engine 4</strong>
                <span className='font-icons icon-blender' style={{marginLeft: '9px'}}></span>
                <strong>Blender</strong>
              </div>

              <p style={{marginTop: '1em'}}>
                <strong>Problem:</strong>   <br />
                The goal of this project was to learn how to combine 3-D computer graphics
                with scripting in order to create an immersive experience.  
              </p>

              <p style={{marginTop: '1em'}}>
                <strong>Description:</strong>   <br />
                Atoll is a simple first person platformer game that requires its players to complete
                three different objectives to progress toward the ending. Atoll also has a showcase
                website built upon the MERN stack. The web app presents in-game features and shows  
                a changelog of development.   
              </p>

              <p style={{marginTop: '1em'}}>
                <strong>Features:</strong>
              </p>
              <ul className='features_ul'>
                <li>Item interaction and challenges</li>
                <li>Dynamic wind animation and audio</li>
                <li>All original textures and models</li>
                <li>Checkpoint system and random object spawn</li>
                <li>Original soundtrack</li>
              </ul>          
            </div>

            <div className="bottom-btns">
              <div className="left client">
                {/* <img src={ClupLogo} /> */}
              </div>       

              <div className="right">
                <a href="https://aboutatoll.herokuapp.com/">
                  <h3>Site</h3>
                </a>
              </div>
            </div>
          </FadeIn>
        </div>
      </section>
    </>
  );
}

FadeIn.propTypes = {
  children: PropTypes.node
};

export default Section2;