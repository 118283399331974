import React from 'react';
import Navbar from '../Navbar';
import Home from '../Home';
import Section1 from '../Section1';
import Section2 from '../Section2';
import Section3 from '../Section3';

function LandingPage() {
  return (
    <>
      <Home />
      <Navbar />
      <Section1 />
      <Section2 />
      <Section3 />
    </>
  );
}

export default LandingPage;