import React, { useEffect } from 'react';
import './section-1.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

type Props = {
  delay: number,
  // Single element or multiple
  children?: JSX.Element | JSX.Element[]
}

function FadeIn({ delay, children }: Props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } 
  }, [ inView ]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{
        duration: 1.1,
        ease: 'easeInOut',
        delay: delay
      }}
      variants={{
        visible: { 
          opacity: 1
        },
        hidden: { 
          opacity: 0
        }
      }}
    >
      { children }
    </motion.div>
  );
}


function Section1() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <>
      <section className='section1'>
        <div className="container sec1">
          <motion.h1
            className='technology_h1'
            ref={ref}
            animate={controls}
            initial="hidden"
            transition={{
              type: 'spring',
              damping: 30,
              mass: 3,
              stiffness: 90,
              delay: 0.1
            }}
            variants={{
              visible: { 
                opacity: 1,
                translateY: '-.1em',
              },
              hidden: { 
                opacity: 0, 
                translateY: '1.8em'
              }
            }}
          >
            Technology        
          </motion.h1>          
          <div className="split sec1-01">
            <div className='tech_card'>
              <FadeIn delay={.65}>
                <h1>BACKEND</h1>
                <div className="tech-icons">
                  <span className='font-icons icon-database'></span>MongoDB
                  <span className='font-icons icon-javascript'></span>Express
                </div>
                <p>
                  <strong> Hosting:</strong> <span className='font-icons icon-aws'></span> Lambda
                </p>
                <p className='tech_p'>
                Most of the paragraphs you see on this webpage are stored within <strong>MongoDB</strong> where data is queried via an <strong>Express</strong> route and Mongoose. 
                The Express server, requests, and models are defined within a <strong>serverless Lambda function</strong> to minimize hosting cost as much as possible. 
                </p>
              </FadeIn> 
            </div>

            <div className='tech_card'>
              <FadeIn delay={1.35}>
                <h1>FRONTEND</h1>
                <div className="tech-icons">
                  <span className='font-icons icon-react'></span>React
                  <span className='font-icons icon-typescript'></span>Typescript
                </div>
                <p>
                  <strong> Hosting:</strong> <span className='font-icons icon-aws'></span> Amplify
                </p>
                <p className='tech_p'>
                This is a <strong>React Typescript</strong> app that is utlizing <strong>AWS Amplify</strong> for data querying.  
                All props used in each component are assigned a data type where HTML children are defined with type <span>JSX.Element[]</span>. 
                I&apos;m also using <strong>Framer Motion</strong> and CSS for all animations.
                </p>
              </FadeIn>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

FadeIn.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number 
};

export default Section1;