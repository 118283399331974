import React, { } from 'react';
import './home.css';
import { ScrollRotate } from 'react-scroll-rotate';
import Cloud01 from '../images/clouds/cloud01.png';
import { motion } from 'framer-motion';
// import TextModel from '../interfaces/TextModel';

// Generic map of array of nodes
function Rain<ListItem>({ items }: { items: ListItem[] }) {
  return (
    <div className="rain">
      {items.map((item, index) => (
        <i key={index}></i>
      ))}
    </div>
  );
}


function Home() {
  // const [aboutMe, setAboutMe] = useState<TextModel | undefined>(undefined);

  // // Getting data for paragraphs
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await API.get('portfolioAPI', '/getText/aboutMe');
  //       setAboutMe(response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);


  let droplets: Array<number> = [];
  for (let i=1; i < 65; i++) {
    droplets.push(i);
  }

  return (
    <section className='bg-home'>
      <div className="clouds">
        <motion.img
          src={Cloud01}
          style={{ translateX: '-33em'}}
          animate={{
            translateX: ['-32.5em', '-32em', '-31.5em', '-31em', '-31.5em', '-32em', '-32.5em']
          }}
          
          transition={{
            duration: 12,
            ease: 'easeInOut',
            loop: Infinity
          }}
        />
      </div>

      <div className="container home">
        <div className="rain">
          <Rain 
            items={droplets}
          />
        </div>
        
        <motion.h1
          className='myName'
          style={{ y: '-4em' }}
          animate={{
            translateY: '3.75em',
          }}
          transition={{
            type: 'spring',
            damping: 15,
            mass: 4,
            stiffness: 33,
            delay: 1
          }}
        >
          <ScrollRotate method={'perc'} loops={.06}>
          Steven Salomon
          </ScrollRotate>
        </motion.h1>
        
        <motion.p 
          className='subHeading'
          style={{ 
            visibility: 'hidden',
            y: '20em'
          }}
          animate={{
            visibility: 'visible',
            translateY: '-20.5em' 
          }}
          transition={{
            type: 'spring',
            damping: 30,
            mass: 3.5,
            stiffness: 80,
            delay: 2
          }}
        >
          SOFTWARE ENGINEER
        </motion.p>

        <div className="aboutMe">
          <motion.div
            style={{ y: '30em' }}
            animate={{
              translateY: '-30em',
            }}
            transition={{
              type: 'spring',
              damping: 15,
              mass: 3,
              stiffness: 33,
              delay: 1.3
            }}
          >
            <motion.p style={{ opacity: 0 }}
              animate={{
                opacity: 1
              }}
              transition={{
                duration: 1.6,
                ease: 'easeInOut',
                delay: 2.9
              }}
            >
              Welcome to my portfolio. <br/><br/>
            </motion.p>

            <motion.p
              style={{ opacity: 0 }}
              animate={{
                opacity: 1
              }}
              transition={{
                duration: 1.6,
                ease: 'easeInOut',
                delay: 3.1
              }}
            >
              I&apos;m a full stack web developer with an ambitious focus on design.<br/><br/>
            </motion.p>

            <motion.p
              style={{ opacity: 0 }}
              animate={{
                opacity: 1
              }}
              transition={{
                duration: 1.6,
                ease: 'easeInOut',
                delay: 3.3
              }}
            >
              Web development has been a part of my life for the past three years. 
              My interest in development transpired from an inherent curiosity for learning.<br/><br/>
            </motion.p>
        
            <motion.p
              style={{ opacity: 0 }}
              animate={{
                opacity: 1
              }}
              transition={{
                duration: 1.6,
                ease: 'easeInOut',
                delay: 3.4
              }}
            >
              Feel free to get in contact with me.<br/>
            </motion.p>
          </motion.div>
        </div>
      
      </div>

    </section>
  );
}

export default Home;