import React from 'react';
import './section-3.css';


function Section3() {
  return (
    <>
      <section className='section3'>
        <div className="container sec3">
          <div>
            <h1>Let&apos;s connect!</h1>
            <p>steven.ses@protonmail.com</p>
            <div className="contact_btns">
              <a href="https://github.com/OnlyInSpace/">
                <span className='icon-github'></span>Github
              </a>
              <a href="https://www.linkedin.com/in/stevensalomon/">
                <span className='icon-linkedin'></span>Linkedin
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section3;