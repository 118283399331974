export const MenuItems = [
  {
    title: 'Home',
    url: '#',
    cName: 'nav-links',
    scroll: 'bg-home',
    offset: 0
  },
  {
    title: 'Tech',
    url: '#',
    cName: 'nav-links',
    scroll: 'section1',
    offset: 50
  },
  {
    title: 'Work',
    url: '#',
    cName: 'nav-links',
    scroll: 'section2',
    offset: 0
  },
  {
    title: 'Contact',
    url: '#',
    cName: 'nav-links',
    scroll: 'section3',
    offset: 0
  }
];