import React, { useState } from 'react';
import { MenuItems } from './MenuItems';
import './navbar.css';
import { Link } from 'react-scroll';

function Navbar() {
  const [clicked, setClicked] = useState<boolean>(false);

  return (
    <>
      <nav className='navbar-items'>
        <div className="menu-icon" onClick={() => setClicked(!clicked)}>
          <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
          { MenuItems.map((item, index) => {
            return(
              <li key={index}>
                <Link onClick={() => setClicked(!clicked)} offset={item.offset} to={item.scroll} spy={true} smooth={true} activeClass='active_link' className={item.cName} href={item.url}>
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={clicked ? 'overlay active' : 'overlay'}></div>
    </>
  );
}

export default Navbar;